import React from 'react';
import Hero from '../components/Hero';
import AboutUs from '../components/AboutUs';
import OurServices from '../components/OurServices';
import Testimonials from '../components/Testimonials';
import ProjectGallery from '../components/ProjectGallery';
import './HomePage.css';


const HomePage = () => {
    return (




        <div>
            <Hero />
            <AboutUs />
            <OurServices />
            <Testimonials />
            <ProjectGallery />
            {/* Add other sections like About Us, Services, etc. here */}
        </div>
        
    );
};

export default HomePage;
