import React, { useState } from 'react';
import { Navbar, Nav, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FaWhatsapp } from 'react-icons/fa';
import './Navbar.css';

const CustomNavbar = () => {
  // Manage the state of the navbar collapse
  const [expanded, setExpanded] = useState(false);

  // Handle the toggling of the navbar
  const handleToggle = () => {
    setExpanded(expanded ? false : true);
  };

  // Collapse the navbar when an item is clicked
  const closeNavbar = () => {
    setExpanded(false);
  };

  return (
    <>
      {/* Secondary Navbar for WhatsApp */}
      <div className="secondary-navbar">
        <Container>
          <div className="secondary-nav-links">
            <a href="https://wa.me/447388824366" target="_blank" rel="noopener noreferrer" className="whatsapp-link">
              <FaWhatsapp className="navbar-icon" /> WhatsApp
            </a>
          </div>
        </Container>
      </div>

      {/* Main Navbar */}
      <Navbar expand="lg" className="custom-navbar" expanded={expanded}>
        <Container>
          <Navbar.Brand as={Link} to="/">F&E Design and Build</Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={handleToggle} />
          <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
            <Nav>
              <Nav.Link as={Link} to="/" onClick={closeNavbar}>Home</Nav.Link>
              <Nav.Link as={Link} to="/about" onClick={closeNavbar}>About Us</Nav.Link>
              <Nav.Link as={Link} to="/services" onClick={closeNavbar}>Services</Nav.Link>
              <Nav.Link as={Link} to="/gallery" onClick={closeNavbar}>Gallery</Nav.Link> 
              <Nav.Link as={Link} to="/contact" onClick={closeNavbar}>
                <button className="contact-us-button">Contact Us</button>
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
};

export default CustomNavbar;
