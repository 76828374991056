import React, { useState } from 'react';
import './GalleryPage.css';
import Lightbox from 'yet-another-react-lightbox';
import 'yet-another-react-lightbox/styles.css';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css'; // Optional blur effect
import { useNavigate } from 'react-router-dom';


// Dynamically load images using require.context for lazy loading
const importAll = (r) => r.keys().map(r);
const N1Images = importAll(require.context('../assets/N1', false, /\.(jpg|jpeg|png)$/));
const E1Images = importAll(require.context('../assets/E1', false, /\.(jpg|jpeg|png)$/));
const N7Images = importAll(require.context('../assets/N7', false, /\.(jpg|jpeg|png)$/));
const E20Images = importAll(require.context('../assets/E20', false, /\.(jpg|jpeg|png)$/));
const NW8Images = importAll(require.context('../assets/NW8', false, /\.(jpg|jpeg|png)$/));

const gallerySections = {
    "N1": N1Images,
    "E1": E1Images,
    "N7": N7Images,
    "E20": E20Images,
    "NW8": NW8Images
};

const GalleryPage = () => {
    const navigate = useNavigate();
    // State to manage which sections are expanded
    const [expandedSections, setExpandedSections] = useState({});

    // State for lightbox
    const [lightboxOpen, setLightboxOpen] = useState(false);
    const [lightboxImages, setLightboxImages] = useState([]);
    const [lightboxIndex, setLightboxIndex] = useState(0);

    // Toggle view more / less
    const toggleViewMore = (area) => {
        setExpandedSections((prev) => ({
            ...prev,
            [area]: !prev[area],
        }));
    };

    // Open Lightbox
    const openLightbox = (images, index) => {
        setLightboxImages(images);
        setLightboxIndex(index);
        setLightboxOpen(true);
    };

    return (

        

        <div className="gallery-page">
            {/* Top Banner */}
            <section className="gallery-hero">
                <h1>Gallery</h1>
                <h2>Explore our projects across various locations in London</h2>
            </section>

            {/* Introductory Paragraph */}
            <section className="gallery-intro">
                <h2>Our Work</h2>
                <p>
                    Take a look at our diverse portfolio showcasing various construction, renovation, and design projects completed in different parts of London. Each project highlights our commitment to quality, attention to detail, and customer satisfaction.
                </p>
            </section>

            {/* Lightbox */}
            {lightboxOpen && (
                <Lightbox
                    slides={lightboxImages.map(image => ({ src: image }))}
                    open={lightboxOpen}
                    close={() => setLightboxOpen(false)}
                    index={lightboxIndex}
                    onIndexChange={setLightboxIndex}
                />
            )}

            {/* Gallery Sections */}
            <section className="gallery-container">
                {Object.entries(gallerySections).map(([area, images], index) => {
                    const isExpanded = expandedSections[area];
                    const imagesToShow = isExpanded ? images : images.slice(0, 8); // Only load 8 images initially

                    return (
                        <div key={index} className="gallery-area">
                            <h2>{area}</h2>
                            <div className="gallery-grid">
                                {imagesToShow.map((image, idx) => (
                                    <div 
                                        key={idx} 
                                        className="gallery-image" 
                                        onClick={() => openLightbox(images, idx)} // Open lightbox on click
                                    >
                                        <LazyLoadImage
                                            src={image}
                                            alt={`Gallery ${area} ${idx + 1}`}
                                            effect="blur" // Optional blur effect
                                            width="100%"
                                            height="auto"
                                        />
                                    </div>
                                ))}
                            </div>
                            {/* Show "View More" or "View Less" button if necessary */}
                            {images.length > 8 && (
                                <button 
                                    className="view-more-button"
                                    onClick={() => toggleViewMore(area)}
                                >
                                    {isExpanded ? 'View Less' : 'View More'}
                                </button>
                            )}
                        </div>
                    );
                })}
            </section>

            {/* Call to Action */}
            <section className="call-to-action">
                <h2>Ready to Get Started?</h2>
                <p>Contact us today to discuss your project and see how we can help bring your vision to life.</p>
                <button className="cta-button" onClick={() => navigate('/contact')}> {/* Use navigate to go to /contact */}
                Contact Us
                </button>
      </section>
        </div>
        
    );
};

export default GalleryPage;
