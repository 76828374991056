import React, { useState } from 'react';
import './Testimonials.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight, faStar } from '@fortawesome/free-solid-svg-icons';

const testimonialsData = [
    {
        title: "Flat Renovation",
        quote: "We required urgent work to be carried out in our flat after some water damage. F&E came out quickly to do a quote and check the flat over and they managed to start work quickly. Over the next couple of weeks, it was quite apparent how efficient and high-quality their work was. I cannot recommend them enough, especially if you are looking for high-quality, and trustworthy, work. Their work was not only top tier, but they were always on time, very polite and tidied up after every day.",
        name: "NW6",
        company: "June 2024",
        rating: 5
    },
    {
        title: "Bathroom 2nd Fix",
        quote: "Florian showed from day one a professional attitude both when discussing the work and during the execution of the work. He was understanding, helpful and considerate. When issues emerged, he was solution-oriented and flexible. In comparison to other builders, he came and got things done as agreed. I can recommend the services of F&E Design and Build Ltd.",
        name: "SW12",
        company: "June 2024",
        rating: 5
    },
    {
        title: "Staircase Railing and Skirting/Beading Installation",
        quote: "Florian and his team have done a fantastic job. We asked them to do a few things to renovate our flat, including painting all the walls, removing the large partition door, fitting the tiles in the kitchen and bathroom, also fitting a new shower etc. All the work was very high standard and done very efficiently. His choice for the tile was excellent and made the kitchen looks nice and posh. Also, we are happy to see that no sign of the partition door attachment as they fixed the wall and repainted it very well after removing it. They left the flat very clean after job is done. Highly recommend.",
        name: "N7",
        company: "May 2024",
        rating: 5
    },
    {
        title: "Paint Walls, Tile Fitting, Removing a Partition Wall",
        quote: "F&E Design and Build Ltd did an excellent job. They were swift, clear and professional in communication. The new wall they built and the rooms look brilliant. They look so fresh and the attention to detail is excellent. They went above and beyond with this job and I’m so glad we commissioned them for this project.",
        name: "E2",
        company: "April 2024",
        rating: 5
    },
    {
        title: "Partition Wall to Turn One Room Into Two",
        quote: "The works was for my elderly mother, Florian and his team carried out the works in a very timely and professional manner. My mother felt very safe and pleased with the work Florian carried out and will hire Florian for some additional work that is needed.",
        name: "NW10",
        company: "April 2024",
        rating: 5
    }
];

const Testimonials = () => {
    const [currentIndex, setCurrentIndex] = useState(0);

    const goToPrevious = () => {
        setCurrentIndex((prevIndex) => (prevIndex === 0 ? testimonialsData.length - 1 : prevIndex - 1));
    };

    const goToNext = () => {
        setCurrentIndex((prevIndex) => (prevIndex === testimonialsData.length - 1 ? 0 : prevIndex + 1));
    };

    const setIndex = (index) => {
        setCurrentIndex(index);
    };

    const { title, quote, name, company, rating } = testimonialsData[currentIndex];

    return (
        <div className="testimonials-section">
            <h2 className="testimonials-heading">Customer Testimonials</h2>
            <p>
                Our clients are at the heart of everything we do. We believe the best way to measure
                our success is through the satisfaction of those we work with. Here are just a few
                testimonials from our many happy customers.
            </p>
            <br></br>
            <div className="testimonial-card">
                <h3 className="testimonial-title">{title}</h3>
                <div className="testimonial-rating">
                    {Array(rating).fill().map((_, i) => (
                        <FontAwesomeIcon key={i} icon={faStar} className="star-icon" />
                    ))}
                </div>
                <p className="testimonial-quote">"{quote}"</p>
                <p className="testimonial-name">- {name}{company && `, ${company}`}</p>
            </div>
            <div className="testimonial-controls">
                <button onClick={goToPrevious} className="testimonial-button">
                    <FontAwesomeIcon icon={faArrowLeft} /> Previous
                </button>
                <button onClick={goToNext} className="testimonial-button">
                    Next <FontAwesomeIcon icon={faArrowRight} />
                </button>
            </div>
            <div className="testimonial-indicators">
                {testimonialsData.map((_, index) => (
                    <span
                        key={index}
                        className={`testimonial-indicator ${currentIndex === index ? 'active' : ''}`}
                        onClick={() => setIndex(index)}
                    />
                ))}
            </div>
        </div>
    );
};

export default Testimonials;
