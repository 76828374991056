import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate for navigation
import './AboutPage.css';
import Testimonials from '../components/Testimonials';


const AboutPage = () => {
  const navigate = useNavigate(); // Initialize navigate

  useEffect(() => {
    // Scroll effect for "Our Process" section
    const processItems = document.querySelectorAll('.process-item');

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('show');
        }
      });
    }, { threshold: 0.5 });

    processItems.forEach((item) => {
      observer.observe(item);
    });

    return () => {
      processItems.forEach((item) => observer.unobserve(item));
    };
  }, []);

  return (

    <div className="about-page">
      {/* Hero Section */}
      <section className="about-hero">
        <h1>About F&E Design and Build</h1>
        <h2>Learn what makes us special</h2>
      </section>

      {/* Company Overview */}
      <section className="about-overview">
        <h2>Who We Are</h2>
        <p>
          F&E Design and Build is a premier construction company with a reputation for delivering
          high-quality design, build, and renovation services. Over the last two decades, 
          we have built a legacy of trust, craftsmanship, and customer satisfaction. From
          small refurbishments to full-scale new builds, we approach every project with
          dedication and attention to detail.
        </p>
        <p>
          We believe in building not just structures but relationships with our clients. 
          Every project starts with a vision, and we make it our mission to bring that vision
          to life with professionalism and care. Our team works closely with clients to 
          ensure each stage of the process reflects their needs and exceeds their expectations.
        </p>
      </section>

      {/* Expertise Section */}
      <section className="about-expertise">
        <h2>Our Expertise</h2>
        <p>
          At F&E Design and Build, we pride ourselves on the diverse range of skills and experience we bring to every project. 
          Whether it's a large-scale build or a simple renovation, our expert team is equipped to handle any challenge. 
          We combine craftsmanship with innovation, ensuring that every project we undertake is built to last.
        </p>
        <ul className="expertise-list">
          <li>20+ years of industry experience</li>
          <li>Customer-centered approach focused on satisfaction</li>
          <li>Certified and fully insured professionals</li>
          <li>Expertise in design and build, extensions, refurbishments, and more</li>
          <li>On-time and on-budget project delivery</li>
          <li>Use of high-quality materials and modern construction techniques</li>
        </ul>
      </section>

      {/* Customer Ratings Section */}
      <section className="customer-ratings">
        <h2>Customer Ratings</h2>
        <p className="intro-text">
          At F&E Design and Build, we take pride in delivering exceptional quality and service. 
          Our customer ratings reflect the high standards we maintain on every project, 
          from our workmanship to reliability, tidiness, and courtesy.
        </p>

        <div className="ratings-overall">
          <div className="overall-rating">
            <span className="rating-number">9.96</span>
            <p>Overall Rating</p>
          </div>
        </div>

        <div className="ratings-grid">
          <div className="rating-item">
            <p>Workmanship</p>
            <div className="progress-bar">
              <div className="fill" style={{ width: "99.2%" }}></div>
            </div>
            <span className="rating-number">9.92</span>
          </div>

          <div className="rating-item">
            <p>Reliability</p>
            <div className="progress-bar">
              <div className="fill" style={{ width: "99.2%" }}></div>
            </div>
            <span className="rating-number">9.92</span>
          </div>

          <div className="rating-item">
            <p>Tidiness</p>
            <div className="progress-bar">
              <div className="fill" style={{ width: "100%" }}></div>
            </div>
            <span className="rating-number">10</span>
          </div>

          <div className="rating-item">
            <p>Courtesy</p>
            <div className="progress-bar">
              <div className="fill" style={{ width: "100%" }}></div>
            </div>
            <span className="rating-number">10</span>
          </div>
        </div>

        <a
          href="https://www.checkatrade.com/trades/fecontractorsltd"
          className="checkatrade-link"
          target="_blank"
          rel="noopener noreferrer"
        >
          Find Us On Checkatrade
        </a>
      </section>

      {/* Our Process Section */}
      <section className="about-process">
        <h2>Our Process</h2>
        <p>
          We follow a streamlined process to ensure each project is handled with efficiency and care.
        </p>
        <ul className="process-list">
          <li className="process-item">
            <div className="process-icon">
              <i className="fas fa-comments"></i> {/* Consultation Icon */}
            </div>
            <strong>Consultation</strong>
            <p>We start by understanding your vision and project requirements. This initial phase includes a detailed assessment of your goals, site conditions, and budget to ensure the project starts on the right foot.</p>
          </li>
          <li className="process-item">
            <div className="process-icon">
              <i className="fas fa-pencil-ruler"></i> {/* Design & Planning Icon */}
            </div>
            <strong>Design & Planning</strong>
            <p>Our expert team creates detailed plans to bring your ideas to life. We collaborate with you to refine designs, select materials, and establish timelines. Every design is tailored to suit your preferences and functional needs.</p>
          </li>
          <li className="process-item">
            <div className="process-icon">
              <i className="fas fa-hammer"></i> {/* Construction Icon */}
            </div>
            <strong>Construction</strong>
            <p>With precision and quality craftsmanship, we bring the design to reality. We maintain constant communication, ensuring you’re informed every step of the way as we transform the blueprint into your dream space.</p>
          </li>
          <li className="process-item">
            <div className="process-icon">
              <i className="fas fa-handshake"></i> {/* Completion & Handover Icon */}
            </div>
            <strong>Completion & Handover</strong>
            <p>We ensure every detail is perfected before handing over the completed project. We conduct a thorough walk-through with you, addressing any final adjustments and ensuring your complete satisfaction with the outcome.</p>
          </li>
        </ul>
      </section>

      {/* Testimonials Section */}
      <section className="about-testimonials">
        <Testimonials />
      </section>

      {/* Call to Action Section */}
      <section className="about-cta">
        <h2>Ready to Get Started?</h2>
        <p>Contact us today to discuss your project and see how we can help bring your vision to life.</p>
        <button className="cta-button" onClick={() => navigate('/contact')}> {/* Use navigate to go to /contact */}
          Contact Us
        </button>
      </section>
    </div>
  );
};

export default AboutPage;
