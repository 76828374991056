import React from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import './ProjectGallery.css';
import projectone from '../assets/project1.jpg';
import projecttwo from '../assets/project2.jpg';
import projectthree from '../assets/project3.jpg';
import projectfour from '../assets/project4.jpg';
import projectseven from '../assets/project7.jpg';
import projectnine from '../assets/project9.jpg';
import project10 from '../assets/project10.jpg';
import project11 from '../assets/project11.jpg';

const projects = [
    { image: projectone, title: 'New Build' },
    { image: projecttwo, title: 'Loft Conversion' },
    { image: projectthree, title: 'Refurbishment' },
    { image: projectfour, title: 'Painting and Decorating' },
    { image: projectseven, title: 'Roofing' },
    { image: projectnine, title: 'Extensions' },
    { image: project10, title: 'Kitchen Remodel' },
    { image: project11, title: 'Bathroom Renovation' }
];

const ProjectGallery = () => {
    const navigate = useNavigate(); // Initialize navigate

    return (
        <div className="project-gallery-section">
            <div className="project-gallery-header">
                <h2 className="project-gallery-heading">Our Recent Projects</h2>
                <p className="project-gallery-text">
                    Explore some of our most recent projects, showcasing the quality and craftsmanship that F&E Design and Build brings to every job. From new builds to refurbishments, we take pride in transforming spaces to meet our clients' needs.
                </p>
            </div>
            <div className="project-gallery-grid">
                {projects.map((project, index) => (
                    <div className="project-card" key={index}>
                        <img src={project.image} alt={project.title} className="project-image" />
                    </div>
                ))}
            </div>
            <button className="view-more-button" onClick={() => navigate('/gallery')}> {/* Navigate to /gallery */}
                View More Projects
            </button>
        </div>
    );
};

export default ProjectGallery;
