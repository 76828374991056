import React from 'react';
import './Footer.css';

const Footer = () => {
    return (
        <footer className="footer-section">
            <div className="footer-content">
                <p className="footer-text">© 2024 F&E Design and Build. All rights reserved.</p>
            </div>
        </footer>
    );
};

export default Footer;
