import React from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import './OurServices.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilRuler, faHome, faExpand, faHardHat, faBuilding, faPaintBrush } from '@fortawesome/free-solid-svg-icons';

const OurServices = () => {
    const navigate = useNavigate(); // Initialize navigate

    const services = [
        { icon: faPencilRuler, title: 'Design and Build', description: 'From concept to completion, we provide comprehensive design and construction services.' },
        { icon: faHome, title: 'Refurbishments', description: 'Revitalise your space with our expert refurbishment services.' },
        { icon: faExpand, title: 'Extensions & Loft Conversions', description: 'Expand your living space with our bespoke extension and loft conversion solutions.' },
        { icon: faHardHat, title: 'Roofing', description: 'Reliable and durable roofing services to protect your home.' },
        { icon: faBuilding, title: 'New Builds', description: 'Quality new build construction tailored to your specifications.' },
        { icon: faPaintBrush, title: 'Painting and Decorating', description: 'Professional painting and decorating to enhance the look of your property.' }
    ];

    return (
        <div className="our-services-section">
            <h2 className="our-services-heading">Our Services</h2>
            <div className="services-content">
                {services.map((service, index) => (
                    <div className="service-card" key={index}>
                        <div className="service-card-content"> {/* Add a wrapper for the content */}
                            <FontAwesomeIcon icon={service.icon} className="service-icon" />
                            <h4>{service.title}</h4>
                            <p>{service.description}</p>
                        </div>
                        <div className="service-card-footer">
                            <button 
                                className="service-button"
                                onClick={() => navigate('/services')} // Add navigation to the services page
                            >
                                Learn More
                            </button>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default OurServices;
