import React from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import { FaHammer, FaPaintBrush, FaHome, FaHardHat, FaWrench, FaBuilding } from 'react-icons/fa';
import './ServicesPage.css';


const services = [
    {
        title: "Design and Build",
        description: "From concept to completion, we provide comprehensive design and construction services across London.",
        icon: <FaWrench />,
        points: [
            "Collaborative approach to design and construction",
            "Customised solutions for all property types",
            "Efficient project management from start to finish",
            "Attention to detail at every stage",
            "Full-service design and build solutions for residential and commercial properties"
        ]
    },
    {
        title: "Refurbishments",
        description: "Revitalise your space with our expert refurbishment services, enhancing both aesthetics and functionality across London.",
        icon: <FaBuilding />,
        points: [
            "Interior and exterior refurbishment expertise",
            "Modernisation of outdated spaces",
            "Energy-efficient upgrades for sustainability",
            "Comprehensive renovation plans tailored to your needs",
            "High-quality materials and professional workmanship"
        ]
    },
    {
        title: "Extensions & Loft Conversions",
        description: "Expand your living space with our bespoke extension and loft conversion solutions available throughout London.",
        icon: <FaHome />,
        points: [
            "Bespoke designs to maximise space",
            "Expert craftsmanship with high standards",
            "Efficient project timelines to minimise disruption",
            "Compliant with all building regulations",
            "Seamless integration of new spaces into existing structures"
        ]
    },
    {
        title: "Roofing",
        description: "Reliable and durable roofing services to protect your home across London.",
        icon: <FaHardHat />,
        points: [
            "New roof installations and repairs",
            "Durable and weather-resistant materials",
            "Expertise in pitched and flat roofs",
            "Routine maintenance and inspections",
            "Comprehensive roofing solutions including gutters, fascias, and soffits"
        ]
    },
    {
        title: "New Builds",
        description: "Quality new build construction tailored to your specifications, serving clients throughout London.",
        icon: <FaHammer />,
        points: [
            "Bespoke home construction from the ground up",
            "Energy-efficient designs with eco-friendly materials",
            "Full project management from planning to completion",
            "High-quality materials and craftsmanship",
            "Customised layouts and finishes to suit your vision"
        ]
    },
    {
        title: "Painting and Decorating",
        description: "Professional painting and decorating to enhance the look and feel of your property across London.",
        icon: <FaPaintBrush />,
        points: [
            "Interior and exterior painting expertise",
            "Decorating services tailored to your style",
            "Use of high-quality paints and finishes",
            "Detail-oriented service for precision and perfection",
            "Full consultation to ensure your desired look is achieved"
        ]
    }
];

const ServicesPage = () => {
    const navigate = useNavigate(); // Initialize useNavigate

    return (


        <div className="services-page">
            {/* Black Banner */}
            <section className="services-hero">
                <h1>Our Services</h1>
                <h2>Learn about the services we offer</h2>
            </section>

            {/* Intro Paragraph */}
            <section className="services-intro">
                <h2>Comprehensive Construction and Refurbishment Services</h2>
                <p>
                    We offer a range of construction and refurbishment services to meet the diverse needs of our clients throughout London. Whether you're looking to build from scratch, enhance your current space, or simply refresh a room, we are here to deliver top-quality solutions tailored to your specific requirements. Our services are designed with a focus on quality, efficiency, and customer satisfaction.
                </p>
            </section>

            {/* Services Cards */}
            <section className="services-container">
                {services.map((service, index) => (
                    <div key={index} className="service-card">
                        <div className="service-icon">{service.icon}</div>
                        <h2>{service.title}</h2>
                        <p>{service.description}</p>
                        <ul>
                            {service.points.map((point, idx) => (
                                <li key={idx}>{point}</li>
                            ))}
                        </ul>
                    </div>
                ))}
            </section>

            {/* Call to Action */}
            <section className="call-to-action">
                <h2>Ready to Get Started?</h2>
                <p>Contact us today to discuss your project and see how we can help bring your vision to life.</p>
                <button className="cta-button" onClick={() => navigate('/contact')}> {/* Use navigate to go to Contact page */}
                    Contact Us
                </button>
            </section>
        </div>
    );
};

export default ServicesPage;
