import React from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import './Hero.css';
import heroVideo from '../assets/background-video.mp4';

const Hero = () => {
    const navigate = useNavigate(); // Initialize navigate

    return (
        <div className="hero-section">
            <video className="hero-video" autoPlay muted loop preload="auto">
                <source src={heroVideo} type="video/mp4" />
                Your browser does not support the video tag.
            </video>

            <div className="hero-overlay"></div>
            <div className="hero-content">
                <h1 className="hero-title">F&E Design and Build</h1>
                <p className="hero-subtitle">Expert quality construction, design, and renovation services tailored to your needs.</p>
                <div className="hero-buttons">
                    <button className="hero-btn" onClick={() => navigate('/contact')}> {/* Navigate to the Contact page */}
                        Contact Us
                    </button>
                    <button className="hero-btn-secondary" onClick={() => navigate('/services')}> {/* Navigate to the Services page */}
                        Our Services
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Hero;
