import React, { useState } from 'react';
import { Card, Button, Container, Row, Col, Form, Spinner } from 'react-bootstrap';
import emailjs from '@emailjs/browser';
import './ContactPage.css';


const ContactPage = () => {
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [formData, setFormData] = useState({
        user_name: '',
        user_email: '',
        user_phone: '',
        service: '',
        message: ''
    });

    // Handle form submission
    const handleSubmit = (event) => {
        event.preventDefault();
        setIsSubmitting(true);

        emailjs.sendForm(
            'service_uwdr25f',
            'template_n8uiq38',
            event.target,
            'u6yNIJYcav2FzOq-F'
        )
        .then((result) => {
            console.log(result.text);
            setIsSubmitted(true);
            setIsSubmitting(false);
            setFormData({ user_name: '', user_email: '', user_phone: '', service: '', message: '' }); // Clear form fields
        }, (error) => {
            console.log(error.text);
            setIsSubmitting(false);
        });
    };

    // Handle input changes to keep form data
    const handleInputChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    return (

        <div className="contact-us-page">
            {/* Banner Section */}
            <section className="contact-hero">
                <h1>Contact Us</h1>
                <h2>We'd love to hear from you</h2>
            </section>

            {/* Contact Cards Section */}
            <Container className="contact-methods">
                <Row className="justify-content-center">
                    <Col md={4} className="d-flex align-items-stretch">
                        <Card className="mb-4 contact-card">
                            <Card.Body>
                                <Card.Title className="contact-heading">Checkatrade Profile</Card.Title>
                                <Card.Text>
                                    We are a proud member of Checkatrade, known for our high-quality work and customer satisfaction.
                                    Visit our profile to read our reviews and learn more about our services.
                                </Card.Text>
                                <Button href="https://www.checkatrade.com/trades/fecontractorsltd" target="_blank" rel="noopener noreferrer" className="contact-button">
                                    Visit Our Checkatrade Profile
                                </Button>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={4} className="d-flex align-items-stretch">
                        <Card className="mb-4 contact-card">
                            <Card.Body>
                                <Card.Title className="contact-heading">Contact Us on WhatsApp</Card.Title>
                                <Card.Text>
                                    Have an urgent inquiry? Reach out to us directly via WhatsApp for a quick response.
                                    We are here to assist you with all your construction and renovation needs.
                                </Card.Text>
                                <Button href="https://wa.me/447388824366" target="_blank" rel="noopener noreferrer" className="contact-button whatsapp-button">
                                    Chat with Us on WhatsApp
                                </Button>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={4} className="d-flex align-items-stretch">
                        <Card className="mb-4 contact-card">
                            <Card.Body>
                                <Card.Title className="contact-heading">Call Us Directly</Card.Title>
                                <Card.Text>
                                    For immediate assistance, give us a call. Our team is ready to help with all your queries.
                                </Card.Text>
                                <Button href="tel:+447388824366" className="contact-button phone-button">
                                    Call Us: +44 7388 824366
                                </Button>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>

            {/* Main Contact Section with Banner and Form */}
            <Container className="contact-form-banner-container">
                <Row className="contact-row">
                    {/* Left Banner Column */}
                    <Col md={6} className="contact-col">
                        <div className="contact-banner">
                            <h2>Get in Touch with Us</h2>
                            <p>
                                We are committed to delivering quality construction services tailored to your needs.
                                Whether you have a question, need a quote, or just want to learn more about our services,
                                we're here to help. Contact us today and let's build something great together.
                            </p>
                        </div>
                    </Col>

                    {/* Right Form Column */}
                    <Col md={6} className="contact-col">
                        <section className="contact-form-section">
                            <h2 className="contact-heading">Contact Us</h2>
                            <Container className="contact-form-container">
                                {!isSubmitted ? (
                                    <Form className="contact-form" onSubmit={handleSubmit}>
                                        <Form.Group controlId="name" className="form-group">
                                            <Form.Label>Full Name <span className="required">*</span></Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="user_name"
                                                placeholder="Enter your full name"
                                                value={formData.user_name}
                                                onChange={handleInputChange}
                                                required
                                            />
                                        </Form.Group>
                                        <Form.Group controlId="email" className="form-group">
                                            <Form.Label>Email <span className="required">*</span></Form.Label>
                                            <Form.Control
                                                type="email"
                                                name="user_email"
                                                placeholder="Enter your email"
                                                value={formData.user_email}
                                                onChange={handleInputChange}
                                                required
                                            />
                                        </Form.Group>
                                        <Form.Group controlId="phone" className="form-group">
                                            <Form.Label>Phone Number <span className="required">*</span></Form.Label>
                                            <Form.Control
                                                type="tel"
                                                name="user_phone"
                                                placeholder="Enter your phone number"
                                                value={formData.user_phone}
                                                onChange={handleInputChange}
                                                required
                                            />
                                        </Form.Group>
                                        <Form.Group controlId="service" className="form-group">
                                            <Form.Label>Service Required <span className="required">*</span></Form.Label>
                                            <Form.Control
                                                as="select"
                                                name="service"
                                                value={formData.service}
                                                onChange={handleInputChange}
                                                required
                                            >
                                                <option value="" disabled>Select a service...</option>
                                                <option value="design-and-build">Design and Build</option>
                                                <option value="refurbishments">Refurbishments</option>
                                                <option value="extensions">Extensions & Loft Conversions</option>
                                                <option value="roofing">Roofing</option>
                                                <option value="new-builds">New Builds</option>
                                                <option value="painting-decorating">Painting and Decorating</option>
                                            </Form.Control>
                                        </Form.Group>
                                        <Form.Group controlId="message" className="form-group">
                                            <Form.Label>Message <span className="required">*</span></Form.Label>
                                            <Form.Control
                                                as="textarea"
                                                name="message"
                                                rows={5}
                                                placeholder="Enter your message"
                                                value={formData.message}
                                                onChange={handleInputChange}
                                                required
                                            />
                                        </Form.Group>
                                        <Button
                                            variant="primary"
                                            type="submit"
                                            className="submit-button"
                                            disabled={isSubmitting}
                                        >
                                            {isSubmitting ? (
                                                <Spinner
                                                    as="span"
                                                    animation="border"
                                                    size="sm"
                                                    role="status"
                                                    aria-hidden="true"
                                                    style={{ color: "#D4AF37" }}
                                                />
                                            ) : (
                                                'Submit'
                                            )}
                                        </Button>
                                    </Form>
                                ) : (
                                    <div className="thank-you-message">
                                        <h3>Thank you for contacting us!</h3>
                                        <p>We have received your message and will get back to you shortly.</p>
                                        <Button onClick={() => setIsSubmitted(false)} className="thank-you-button">
                                            Send Another Message
                                        </Button>
                                    </div>
                                )}
                            </Container>
                        </section>
                    </Col>
                </Row>
            </Container>
        </div>
        
    );
};

export default ContactPage;
