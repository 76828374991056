import React from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import './AboutUs.css';
import logofe from '../assets/logofe.jpg';

const AboutUs = () => {
    const navigate = useNavigate(); // Initialize navigate

    return (
        <div className="about-us-section">
            <h2 className="about-us-heading">About Us</h2>
            <p className="about-us-intro">
                F&E Design and Build is a leading construction company with a reputation for delivering high-quality projects on time and within budget. With over two decades of experience, we specialise in a wide range of services, from design and build to refurbishments and new builds. Our team of experts is dedicated to providing reliable and precise construction services tailored to meet the specific needs of our clients.
            </p>
            <p className="about-us-intro">
                At F&E Design and Build, we believe in integrity and transparency. Our commitment to these values ensures that we build lasting relationships with our clients, based on trust and mutual respect. We are constantly innovating, using the latest technologies and sustainable practices to deliver cutting-edge designs and solutions. Whether it's a small renovation or a large-scale commercial project, we bring the same level of dedication and expertise to every job.
            </p>
            <br />
            <br />
            <h3 className="about-us-title">Our Values</h3>
            <div className="about-us-content">
                <div className="about-us-point">
                    <i className="fas fa-tools about-us-icon"></i>
                    <h4>Experience</h4>
                    <p>Over 20 years of industry experience. We bring unparalleled expertise to every project, ensuring the highest standards of quality and efficiency.</p>
                </div>
                <div className="about-us-point">
                    <i className="fas fa-star about-us-icon"></i>
                    <h4>Quality</h4>
                    <p>Committed to excellence and precision. Our meticulous attention to detail ensures that every project we undertake is completed to the highest standards.</p>
                </div>
                <div className="about-us-point">
                    <i className="fas fa-shield-alt about-us-icon"></i>
                    <h4>Integrity</h4>
                    <p>Honest and transparent services. We value trust and honesty, building strong relationships with our clients through open communication and accountability.</p>
                </div>
                <div className="about-us-point">
                    <i className="fas fa-lightbulb about-us-icon"></i>
                    <h4>Innovation</h4>
                    <p>Cutting-edge designs and solutions. By integrating the latest technologies, we create innovative and sustainable solutions for modern construction challenges.</p>
                </div>
            </div>
            <div className="about-us-footer">
                <img src={logofe} alt="Our Team" className="about-us-image" />
                <button 
                    className="about-us-button"
                    onClick={() => navigate('/about')} // Navigate to the /about page
                >
                    Learn More About Us
                </button>
            </div>
        </div>
    );
};

export default AboutUs;
